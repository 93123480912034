<template>
  <div class="sidebar-left">

    <div class="sidebar">

      <!-- Logged In User Profile Sidebar -->
      <user-profile-sidebar
        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
      />

      <!-- Sidebar Content -->
      <div
        class="sidebar-content"
        :class="{'show': mqShallShowLeftSidebar}"
      >

        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <div class="sidebar-profile-toggle">
              <b-avatar
                size="42"
                class="cursor-pointer badge-minimal avatar-border-2"
                :src="userLogin.imagem"
                :text="avatarText(userLogin.name)"
                variant="primary"
                badge
                badge-variant="success"
              />
            </div>
            <!-- Search -->
            <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                placeholder="Pesquisar..."
                autocomplete="off"
              />
            </b-input-group>
          </div>
        </div>
        <app-collapse
          class="listaContatos"
        >
          <app-collapse-item
            title="Contatos"
            icon="UsersIcon"
            :is-visible="listaContatos"
            :force-close="forceClose"
            @visible="alterarExibirContatos"
          >
            <ul class="chat-user-list-wrapper list-group ">
              <chat-contact
                v-for="contact in filteredContacts"
                :key="contact.uuid"
                :user="contact"
                tag="li"
                @click="$emit('open-chat', contact.uuid)"
              />
            </ul>
          </app-collapse-item>

        </app-collapse>
        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >

          <!-- Chats Title -->
          <h5 class="ml-1 mt-2">
            <feather-icon
              icon="MessageCircleIcon"
              size="21"
            />
            Conversas
          </h5>
          <div
            v-if="$store.state['app-chat'].loads.chats"
            class="d-flex justify-content-center"
          >
            <b-spinner
              variant="primary"
              class="mr-1"
              small
              type="grow"
            />
          </div>
          <!-- Chats -->
          <ul
            v-else
            class="chat-users-list chat-list media-list"
          >

            <chat-contact
              v-for="contact in filteredChatsContacts"
              :key="contact.id"
              :user="contact"
              tag="li"
              :class="{'active': activeChatContactId === contact.id}"
              is-chat-contact
              @click="$emit('open-chat', contact.id)"
            />

          </ul>

        </vue-perfect-scrollbar>
      </div>

    </div>
  </div>
</template>

<script>
import {
  BAvatar, BFormInput, BInputGroup, BInputGroupPrepend, BSpinner,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import ChatContact from './ChatContact.vue'
import UserProfileSidebar from './UserProfileSidebar.vue'

export default {
  components: {
    BSpinner,

    // BSV
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    AppCollapse,
    AppCollapseItem,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    ChatContact,
    UserProfileSidebar,
  },
  props: {
    chatsContacts: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    profileUserMinimalData: {
      type: Object,
      required: false,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const userLogin = computed(() => store.state.app.user)
    // const user = computed(() => store.state.user)
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const resolveChatContact = userId => props.contacts.find(contact => contact.id === userId)

    // Search Query
    const searchQuery = ref('')

    const searchFilterFunction = contact => contact && (contact.name || contact.fullName) && ((contact.name ? contact.name.toLowerCase() : '') || (contact.fullName ? contact.fullName.toLowerCase() : ''))
      .includes(searchQuery.value.toLowerCase())

    const filteredChatsContacts = computed(() => props.chatsContacts.filter(searchFilterFunction))
    const filteredContacts = computed(() => props.contacts.filter(searchFilterFunction))

    watch(searchQuery, newValue => {
      emit('update:searchQuery', newValue)
    })

    const forceClose = ref(false)
    const listaContatos = computed(() => store.state['app-chat'].exibirContatos)
    watch(() => store.state['app-chat'].forceCloseContactsSidebar, newValue => {
      if (newValue && store.state['app-chat'].forceCloseContactsSidebar) {
        forceClose.value = true
        store.commit('app-chat/alterarForceCloseContactsSidebar')
        if (store.state['app-chat'].exibirContatos) {
          store.commit('app-chat/alterarExibirContatos')
        }
      }
    })

    const alterarExibirContatos = () => {
      store.commit('app-chat/alterarExibirContatos')
      forceClose.value = false
    }

    return {
      // Search Query
      searchQuery,
      filteredChatsContacts,
      filteredContacts,

      // UI
      resolveChatContact,
      perfectScrollbarSettings,
      userLogin,
      avatarText,

      alterarExibirContatos,
      listaContatos,
      forceClose,
    }
  },

}
</script>
<style lang="scss" scoped>
.listaContatos .card {
  box-shadow: none !important;
  border-right: solid 1px #f1f1f1;

}
</style>
