<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      size="35"
      :src="user.imagem || user.avatar"
      :badge="isChatContact"
      class="badge-minimal"
      :badge-variant="resolveAvatarBadgeVariant(user.status)"
      variant="secondary"
    />
    <div class="chat-info flex-grow-1">
      <div class="mb-0 font-size-8">
        {{ user.name || user.fullName }}
      </div>
      <p class="card-text text-truncate font-size-8">
        {{ isChatContact ? user.chat.last_message.message : user.funcao }}
      </p>
    </div>
    <div
      v-if="isChatContact"
      class="chat-meta text-nowrap"
    >
      <small
        class="float-right mb-25 chat-time"
      >{{
        user.chat.last_message.time
      }}</small>
      <b-badge
        v-if="user.chat.unseenMsgs"
        pill
        variant="primary"
        class="float-right"
      >
        {{ user.chat.unseenMsgs }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { convertStringToDate } from '@core/utils/filter'
import useChat from './useChat'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat()
    return {
      convertStringToDate,
      resolveAvatarBadgeVariant,
    }
  },
}
</script>
