import axios from '@axios'

export default {
  namespaced: true,
  state: {
    exibirContatos: false,
    forceCloseContactsSidebar: false,
    chatAberto: null,
    paginacao: {
      pageAtual: 1,
      userId: null,
    },
    loads: {
      chats: false,
      chatLog: false,
      sendMessage: false,

    },
  },
  getters: {},
  mutations: {
    alterarExibirContatos(state) {
      state.exibirContatos = !state.exibirContatos
    },
    alterarForceCloseContactsSidebar(state) {
      state.forceCloseContactsSidebar = !state.forceCloseContactsSidebar
    },
    alterarLoads(state, data) {
      state.loads[data] = !state.loads[data]
    },
    setChatAberto(state, data) {
      state.chatAberto = data
    },
    setPage(state, {
      pageAtual,
      userId,
    }) {
      state.paginacao.pageAtual = pageAtual
      state.paginacao.userId = userId
    },
  },
  actions: {
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/chats')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchContacts({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/chats/contacts', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/chat/users/profile-user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChat({ commit }, {
      userId,
      pageAtual,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/chats', {
            user: userId,
            page: pageAtual,
            perPage: 100,

          })
          .then(response => {
            const paginacao = {
              pageAtual: response.data.page,
              userId: response.data.contact.id,
            }

            commit('setPage', paginacao)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, {
      contactId,
      message,
      chatId,
    }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('user', contactId)
        if (typeof message === 'object' && message instanceof Blob) {
          formData.append('file', message)
          formData.append('body', '')
        } else {
          formData.append('body', message)
        }

        axios
          .post(`/chats/${chatId}/messages`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
